<template>
  <div class="introducePage">
    <div class="section-aside">
      <p>北斗心灵生命</p>
      <p>关怀服务中心</p>
    </div>
    <div class="section-main">
      <div class="main-content">
        <p>
          宁波市海曙区北斗心灵生命关怀服务中心（英文名：The Big Dipper Spiritual
          Life Care Service
          Center）于2014年5月在宁波市海曙区民政局备案成立，参与社会公益服务活动，2018年8月20日正式注册民办非企业机构，完成了从松散的业余志愿者队伍向专业的社会民非服务机构转型。机构办公地址：宁波市海曙区周江岸路16号（海曙区民政局）三楼310室，办公电话：0574-87683877
          服务业务范围：对高龄、重症群体及家属开展社会工作服务；培训、宣传、助推临终关怀服务事业发展；承接政府委托其他工作事项。
          北斗心灵生命关怀服务中心实施的社会服务项目多次荣获省市大奖：2016年浙江省第五届慈善项目奖、2019年度浙江省最佳志愿服务项目、2019年宁波市新时代文明实践志愿服务项目大赛铜奖、2019年“寻访初心”海曙区十佳锋领公益项目。
        </p>
        中心宗旨：关爱生命 真心呵护<br />
        中心使命：助推“安宁疗护服务”事业稳健发展，让末期生命个案在全方位照护中提升生命质量绽放生命尊严。<br />
        中心愿景：让生命呵护生命成为一种生活方式<br />
        中心定位：政府职能服务的延续补充<br />
        中心精神：点滴爱心，汇聚成城，惠润四方<br />
        中心崇尚：以宽容平和心态参与公益，把平凡小事做到极致。<br />
        中心社会服务功能：1、招募、培训助老、临终关怀服务志愿者。推广“安宁照护服务”理念。<br />
        2、为高龄、重症、独居空巢、病危及临终患者人群和家庭提供可持续性的专业人文关怀服务。<br />
        3、专业志愿服务下沉到街道社区、养老院、医院，在宁波市全市区内建立服务联络网点，求助受理后，及时安排介入对接启动程序。<br />
        4、服务流程：1）求助者拨打求助电话0574-87683877/15988657738；2）自诉自身或亲属状况和需求；3）北斗心灵受理后会及时建档、评估、核查、归类、介入对接、持续跟踪服务。4）因人而异，不断完善个案服务工作进程。
        <p>
          志愿者招募：长期招募具有“奉献、友爱、互助、进步”志愿者精神的爱心人士参与申请。申请者须填写宁波市海曙区北斗心灵生命关怀服务中心志愿服务工作申请表，志愿者申请报名电话：0574-87683877或15988657738，联系人：杨静。现场报名地址：海曙区周江岸路16号三楼310室。
        </p>
        <p>
          感谢您对北斗心灵生命关怀服务中心的信任和参与，您的一份慷慨资助将为更多重症病危家庭带去理性指导、关怀服务和祝福。
          捐赠通道：捐赠100元以上并获得免税发票的捐赠人，请通过以下渠道，宁波市海曙区北斗心灵生命关怀服务中心在宁波市慈善总会专设的专项基金“北斗心灵慈善基金”，用于生命关怀服务的专项基金。
        </p>
        <p>秘书长：杨静 15988657738</p>
        <p>办公室：0574-87683877</p>
      </div>

      <div class="main-video">
        <audio
          controls
          id="audios"
          height="50"
          autoplay="autoplay"
          src="./audio/introducePageSeven_bg.mp3"
        ></audio>
      </div>

      <div class="main-picture">
        <img src="./img/introducePageSeven_show_one.jpg" al t="" />
      </div>

      <div class="main-picture">
        <img src="./img/introducePageSeven_show_two.jpg" alt="" />
      </div>

      <div class="main-picture">
        <img src="./img/introducePageSeven_show_three.jpg" alt="" />
      </div>

      <div class="main-picture">
        <img src="./img/introducePageSeven_show_four.jpg" alt="" />
      </div>

      <div class="main-picture">
        <img src="./img/introducePageSeven_show_five.jpg" alt="" />
      </div>

      <div class="main-picture">
        <img src="./img/introducePageSeven_show_sex.jpg" alt="" />
      </div>
      <div class="main-picture">
        <img src="./img/introducePageSeven_show_seven.jpg" alt="" />
      </div>
      <div class="main-picture">
        <img src="./img/introducePageSeven_show_eight.jpg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      timer: null,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      const that = this;
      //--创建页面监听，等待微信端页面加载完毕 触发音频播放
      document.addEventListener("WeixinJSBridgeReady", function () {
        that.$nextTick(() => {
          document.getElementById("audios").play();
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.introducePage {
  position: relative;
  box-sizing: border-box;
  min-height: 100vh;
  padding: 378px 0 80px;
  background: url(./img/introducePage_bg.png) no-repeat top left;
  background-size: 100% auto;
  background-color: #dfeeff;
  .section-aside {
    position: absolute;
    top: 160px;
    left: 36px;
    font-size: 72px;
    color: #ffffff;
    line-height: 84px;
    text-shadow: 5px 2px #4a7196;
    p:last-child {
      padding-left: 40px;
    }
  }
  .section-main {
    box-sizing: border-box;
    padding: 38px 30px 0px;
    width: 100%;
    background: url(./img/introducePage_across.png) no-repeat top center;
    background-size: calc(100% - 20px) 80px;
    .main-content {
      // width: 100%;
      padding: 50px 30px 56px;
      background-color: #fff;
      border-radius: 0 0 6px 6px;
      font-size: 28px;
      line-height: 40px;
      color: rgba(0, 0, 0, 0.85);
      // text-align-last: justify;
      text-align: justify;
      text-indent: 2em;
    }
    .main-video {
      position: relative;
      margin-top: 30px;
      background-color: #fff;
      border-radius: 0 0 6px 6px;
      padding: 40px 22px 40px;
      text-align: center;
      height: 100px;
      ::v-deep video {
        width: 100%;
        height: 100%;
        object-fit: fill;
        background: #fff;
      }
    }
    .main-picture {
      position: relative;
      margin-top: 30px;
      background-color: #fff;
      border-radius: 0 0 6px 6px;
      padding: 68px 22px 94px;
      img {
        width: 100%;
        // width: 646px;
        // height: 486px;
      }
      &:not(:last-child)::after {
        content: "";
        display: block;
        position: absolute;
        bottom: -68px;
        left: 28px;
        width: 634px;
        height: 106px;
        background: url(./img/introducePage_vertical.png) no-repeat top center;
        background-size: 100% 100%;
        z-index: 1;
      }
    }
  }
}
</style>
